.inner-new-transfer{
  width: 100%;
  max-width: 480px;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1050;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.back-button{ width: 30px; height: 30px; border-radius: 15px; cursor: pointer; color: white}
.back-button:hover{
  background-color: white;
  color: black;
}

.vertical-scroll::-webkit-scrollbar,
.preview-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.vertical-scroll::-webkit-scrollbar-track {
  background: white;
}
.vertical-scroll::-webkit-scrollbar-thumb,
.preview-container::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
  height: 20px;
}

.btn-batch-manage{
  border-radius: 4px !important;
}

.rounded{
  min-width: 25px;
  text-align: center;
  border-radius: 10px !important;
}

.refresh-btn{
  border-radius: 20px;
}

.filter-btn-active{
  background-color: #200f21;
  color: white;
}

.flex-1{
  flex: 1;
}