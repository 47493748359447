body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.9em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.img-responsive {
  justify-self: center;
}

.back-btn {
  background-color: #30D5C8;
  border-radius: 4px;
  border: none;
  width: 70px;
  height: 30px;
}

.back-btn:hover {
  color: white;
}

.info ListGroupItem {
  margin-right: 10px;
}

.Appuser-banner {
  background-color: #30D5C8;
}

Label {
  color: #30D5C8;
  font-size: 16px;
  font-weight: bold;
}

.btn-update {
  background-color: #30D5C8;
  border-radius: 8px;
  border: none;
}

.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid #dfe2e5;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #30D5C8;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #8f9397;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f0f1f3;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file, .form-control-range {
  display: block;
  width: 100%;
}

.tr {
  background-color: #f8f8f8;
  line-height: 2;
  border-bottom: 5px solid;
  border-color: #e9ecef;
}

.tr td, th {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.loader-container{
  position: relative;
}

.loading-screen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.c-button{
  outline:none;
  height: 34px;
  text-align: center;
  min-width: 100px;
  background: #fff;
  border: 1px solid #62d2a2;
  color:#62d2a2;
  letter-spacing:1px;
  text-shadow:0;
  font-size:12px;
  text-transform: uppercase;
}

.c-button:focus{
  outline: none;
}

.c-button.selected{
  background-color: #62d2a2;
  color: #fff;
  font-weight: bold;
}

.textarea{
  resize: none;
  height: 150px !important;
}

.r-button{
  outline:none;
  height: 34px;
  text-align: center;
  width: 34px;
  background: #fff;
  border: none;
  border-radius: 34px;
  color:#62d2a2;
  text-shadow:0;
  font-size:12px;
  text-transform: uppercase;
}

.r-button.danger{
  background-color: #c70039;
  color: #fff;
}

.r-button.success{
  background-color: #2b580c;
  color: #fff;
}

.pill-list-container{
  max-height: 150px;
  overflow-y: auto;
}

.centered-td{
  vertical-align: middle;
}